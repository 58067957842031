import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

// import RawJSON from '../Testing/RawJSON';
import BlogIndex from '../BlogIndex/BlogIndex';
import { Router } from '@reach/router';
import PrivateRoute from '../PrivateRoute';
import MemberItemPage from './MemberItemPage';

function InsightDispatch() {
    const { allInsightDispatch: { nodes: dispatches }} = useStaticQuery(graphql`
        query {
            allInsightDispatch (sort: {order: DESC, fields: date}, filter: {id: {ne: "TEMPLATE"}}) {
                nodes {
                    audioUrl
                    imageUrl
                    duration
                    summary
                    title
                    id
                    date(formatString: "MMMM DD, YYYY")
                    slug
                }
            }
        }
    `);

    return (
        <section>
            <Router>
                <PrivateRoute path='/' component={BlogIndex} posts={dispatches} />
                {dispatches.map(dispatch => 
                    <PrivateRoute path={dispatch.slug} component={MemberItemPage} item={dispatch} />
                )}
            </Router>
            {/* <RawJSON data={dispatches} title="Insight Dispatch - dispatches" /> */}
        </section>
    );
}

export default InsightDispatch;