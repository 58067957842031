import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { FirebaseContext } from '../../Firebase'

function AdminNav() {
    const { user } = React.useContext(FirebaseContext);

    return (
        <NAV>
            <ul>
                {/* first link can't do a partiallyCurrent match because it would match everything */}
                {/* <li><Link to="/admin" activeClassName="activeMemberPage">Admin</Link></li> */}
                <li><LINK to="/members/createuser">Create User</LINK></li>
                <li><LINK to="/members/uploadcontent">Upload Content</LINK></li>
                <li>{user.email}</li>
                {/* <li><LINK to="/admin/momentumcalls">Other...</LINK></li>
                <li><LINK to="/admin/courses">Other...</LINK></li> */}
            </ul>
        </NAV>
    );
}

// from https://spectrum.chat/gatsby-js/general/how-to-style-current-link-using-emotion-css~339d2cf0-00b0-4d01-945f-9d485ed62963
function LINK(props) {
    return (
        <Link
        {...props}
        getProps={function isActiveLink({ isPartiallyCurrent }) {
            return {style: {color: isPartiallyCurrent ? 'black' : ''},};
        }}
        />
    );
}

const NAV = styled('nav')`
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #969696;
    border-bottom: 1px #f5f5f5 solid;

    ul {
        display: flex;
        justify-content: center;
        align-content: center;
        padding-left: 0; // Removes left padding from bullet
        padding: 0 1.0875rem;
        list-style: none;
        padding-top: 7px;
        margin-bottom: 0;

        @media (max-width: 735px) {
            ${'' /* justify-content: space-between; */}
            margin: 5px 5px 0;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            border-top: 10px dashed red;
            border-bottom: 10px dashed red;
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.4s ease-out;
    }

    a:hover {
        color: #09F;
    }

    // color active link
    .activeMemberPage {
        color: #000;
    }

    li {
        padding: 8px 20px;
        font-size: 15px;

        @media (max-width: 750px) {
            text-align: center;
        }

        @media (max-width: 600px) {
            padding: inherit;
        }
    }
`;

export default AdminNav;