import React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import styled from '@emotion/styled';

// import RawJSON from '../Testing/RawJSON';
import { colonSeparatedTimeToCasual } from '../../utilities/utilities';

import MemberItemNav from './MemberItemNav';

function MemberItemPage({ item }) {
    const playerRef = React.useRef()
    const [speed, setSpeed] = React.useState(1)

    function speedHandler() {
        let speedStep = 0.5
        if (speed === 1 || speed === 1.25) {
            speedStep = 0.25
        }
        if (speed === 2.5) {
            playerRef.current.audio.current.playbackRate = 1
            setSpeed(1)
        } else {
            const newSpeed = speed + speedStep
            playerRef.current.audio.current.playbackRate = newSpeed
            setSpeed(newSpeed)
        }
    }

    return (
        <WRAPPER>
            <H1>{item.title}</H1>
                <BYLINE>
                    {/* {item.date && <DATE>{item.date} • </DATE>} */}
                    {item.duration && <DURATION>{colonSeparatedTimeToCasual(item.duration)}</DURATION>}
                </BYLINE>
            <MemberItemNav audioUrl={item.audioUrl} amazonUrl={item.amazonUrl} />
            <TEMPIMAGE src={`${item.imageUrl}/600x200`} />
            <AudioPlayer
                preload='none'
                src={item.audioUrl}
                customAdditionalControls={[
                    <button onClick={speedHandler}>{speed + 'x'}</button>
                ]}  // hides loop button
                style={{width: '300px', margin: '0 auto'}}
                defaultCurrentTime="00:00"
                defaultDuration={item.duration}
                ref={playerRef}
            />
            {item.summary && <SUMMARY id='summary' dangerouslySetInnerHTML={{__html: item.summary}} />}
            {/* {item.transcript && <TRANSCRIPT id='transcript'>{item.transcript}</TRANSCRIPT>} */}
            {/* <comments></comments> */}

            {/* <RawJSON data={item} title="MemberItemPage - item"/> */}

        </WRAPPER>
    );
}

const WRAPPER = styled('section')`
    padding-bottom: 50px;
`;

const H1 = styled('h1')`
    width: 60%;
    margin: 30px auto 0;
    text-align: center;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 800px) {
        width: 80%;
    }

    @media (max-width: 400px) {
        font-size: 20px;
    }
`;

const BYLINE = styled('div')`
    text-align: center;
`;

const DATE = styled('h3')`
    display: inline;
`;

const DURATION = styled('h3')`
    display: inline;
`;

const TEMPIMAGE = styled('img')`
    display: block;
    height: 280px;
    max-width: 60%;
    margin: 0 auto 50px;
    background-size: cover;
    object-fit: cover;
    background-position-x: center;
    background-position-y: center;
    flex-shrink: 0;
    border-radius: 30px;
`;

const SUMMARY = styled('div')`
    width: 60%;
    margin: 50px auto;

    @media (max-width: 800px) {
        width: 80%;
    }
`;

export default MemberItemPage;