import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Router } from "@reach/router";

import PrivateRoute from '../PrivateRoute';
// import RawJSON from '../Testing/RawJSON';
import BlogIndex from '../BlogIndex/BlogIndex';
import MemberItemPage from './MemberItemPage';

function BookSyntheses() {
    const { allBookSyntheses: { nodes: books }} = useStaticQuery(graphql`
        query {
            allBookSyntheses (filter: {id: {ne: "TEMPLATE"}}) {
                nodes {
                    audioUrl
                    imageUrl
                    duration
                    id
                    # was used to query image from Firebase Storage
                    #localImage {
                    #    childImageSharp {
                    #        fluid(maxWidth: 800, maxHeight: 350) {
                    #            ...GatsbyImageSharpFluid_withWebp
                    #        }
                    #    }
                    #}
                    summary
                    title
                    slug
                }
            }
        }
    `);

    // temp
    // books[0].hero = books[0].localImage.childImageSharp.fluid;

    return (
        <section>
            {/* <BlogIndex posts={books} /> */}
            <Router>
                <PrivateRoute path='/' component={BlogIndex} posts={books} />
                {books.map(book => (
                    <PrivateRoute path={book.slug} component={MemberItemPage} item={book} />
                ))}
            </Router>
            {/* <RawJSON data={books} title="BookSyntheses - books"/> */}
        </section>
    );
}

export default BookSyntheses;