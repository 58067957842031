import React from 'react';
import styled from '@emotion/styled';
import { FirebaseContext } from '../../Firebase';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {dateStringToArgs, newLineToParagraphs} from '../../../utilities/utilities';

function UploadContent() {
    const {firebase} = React.useContext(FirebaseContext);

    const emptyFormValues = {
        title: '',
        summary: '',
        imageUrl: 'https://source.unsplash.com/', // prefilled with unsplash
        audioUrl: '',
        duration: '',
        date: '',
        file: undefined,
        target: 'dispatch',
        testing: false,
    };

    const [formValues, setFormValues] = React.useState(emptyFormValues)
    const [status, setStatus] = React.useState(null) // null, 'loading', 'done'
    const [showImage, setShowImage] = React.useState(false)
    const [showAudio, setShowAudio] = React.useState(false)
    const [uploadUrl, setUploadUrl] = React.useState('')

    function handleSubmit(event) {
        event.preventDefault();
        setStatus('loading')
        firebase.uploadContent({
            title: formValues.title,
            summary: newLineToParagraphs(formValues.summary),
            imageUrl: formValues.imageUrl,
            audioUrl: formValues.audioUrl,
            duration: formValues.duration,
            date: dateStringToArgs(formValues.date),
            file: formValues.file,
            target: formValues.target,
            testing: formValues.testing,
        })
        // TODO error handling in case login is unsuccessful
        // this only works if there are no problems
        .then(result => {
            // console.log('back from upload call. result = ', result)
            setStatus('done')
            setUploadUrl(result)
        });
    }

    function handleReset() {
        setFormValues(emptyFormValues)
        setStatus(null)
        setUploadUrl('')
    }

    function handleTestClick() {
        const date = new Date().toISOString()
        setFormValues({
            title: `test${date}`,
            summary: `test${date}`,
            imageUrl: 'https://source.unsplash.com/', // prefilled with unsplash
            audioUrl: `test${date}`,
            duration: `test${date}`,
            date: '2030-07-26',
            file: undefined,
            target: 'synthesis',
            testing: true,
        })
    }

    function handleInputChange(event) {
        event.persist(); // don't need this in React v17
        let value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked
        } else if (event.target.type === 'file') {
            value = event.target.files[0]
        } else {
            value = event.target.value
        }
        setFormValues(currentValues => ({
                ...currentValues,
                [event.target.name]: value,
            })
        );
    }

    function handleTriggerBuild() {
        setStatus('loading')
        const xhr = new XMLHttpRequest()
        xhr.addEventListener('load', () => {
            setStatus('done')
        })
        xhr.open('POST', process.env.GATSBY_TRIGGER_NETLIFY_BUILD)
        xhr.send()
    }

    return (
        <div>
        <h1 style={{textAlign: 'center'}}>Upload Content</h1>
            <FORM onSubmit={handleSubmit} onReset={handleReset}>
                <input value={formValues.title} onChange={handleInputChange} name='title' placeholder='Title' type='text' minLength={3} />
                <textarea value={formValues.summary} onChange={handleInputChange} name='summary' placeholder='Summary' rows="10" type='text' />
                <input value={formValues.imageUrl} onChange={handleInputChange} name='imageUrl' placeholder='Image URL' type='text' minLength={3} />
                <button type='button' onClick={() => setShowImage(!showImage)}>{showImage ? 'Hide' : 'Preview'} Image</button>
                {showImage && <img src={formValues.imageUrl} alt='' />}
                <input value={formValues.audioUrl} onChange={handleInputChange} name='audioUrl' placeholder='Audio URL (if not uploading file)' type='text' minLength={3} />
                <button type='button' onClick={() => setShowAudio(!showAudio)}>{showAudio ? 'Hide' : 'Preview'} Audio</button>
                {showAudio && 
                    <AUDIOPLAYER
                        preload='none'
                        src={formValues.audioUrl}
                        customAdditionalControls={[]}  // hides loop button
                    />
                }
                <input value={formValues.duration} onChange={handleInputChange} name='duration' placeholder='Duration' type='text' minLength={3} />
                Enter date as 'YYYY-MM-DD'.
                <input value={formValues.date} onChange={handleInputChange} name='date' placeholder='Date' type='date' />
                <select value={formValues.target} name='target' onBlur={() => {}} onChange={handleInputChange} required>
                    <option value='dispatch'>dispatch</option>
                    <option value='synthesis'>synthesis</option>
                </select>
                <input type='file' name='file' onChange={handleInputChange} />
                <div>
                    <label htmlFor='testing'>Testing? (document ID will be 'test')
                        <input type='checkbox' id='testing' name='testing' checked={formValues.testing} onChange={handleInputChange} />
                    </label>
                </div>
                <button type="submit">Upload</button>
                <button type="reset">Reset</button>
                <button type="button" onClick={handleTestClick}>Enter Test Info</button>
                <button type="button" onClick={handleTriggerBuild}>Trigger Netlify Build</button>
                {status && <div style={{
                    color: status === 'loading' ? 'red' : 'green'
                }}>{status}</div>}
                {uploadUrl && <div>{uploadUrl}&nbsp;&nbsp;<span style={{cursor: 'pointer'}}  onClick={() => navigator.clipboard.writeText(uploadUrl)}>📋</span></div>}
            </FORM>
        </div>
    );
}

const FORM = styled('form')`
    display: flex;
    flex-direction: column;
    padding: 0 20%;

    > * {
        margin: 10px 0;
    }

`;

const AUDIOPLAYER = styled(AudioPlayer)`
    width: 350px;
    margin: 0 auto;

    @media (max-width: 430px) {
        width: 250px;
    }
`;

export default UploadContent;