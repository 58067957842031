import React from 'react';
import styled from '@emotion/styled';

function MemberItemNav({ amazonUrl, audioUrl }) {
    return (
        <nav>
            <UL>
                <li><a href="#summary">Summary</a></li>
                {/* <li><a href="#">Comments</a></li> */}
                {/* <li><a href="#transcript">Transcript</a></li> */}
                <li><a href={audioUrl} download>Download</a></li>
                {amazonUrl && <li><a href={amazonUrl}>Amazon</a></li>}
            </UL>
        </nav>
    );
}

const UL = styled('ul')`
    display: flex;
    justify-content: center;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

    li {
        list-style: none;
    }

    a {
        text-decoration: none;
    }
`;

export default MemberItemNav;