// import { navigate } from '@reach/router';
import React from 'react';
// import { navigate } from 'gatsby';
import { FirebaseContext } from './Firebase';

function PrivateRoute({ admin, component: Component, location, ...rest }) {

    // const myContext = useContext(FirebaseContext);
    // const { user } = myContext;
    // console.log(user);

    // I think I had a problem with the above because it was executing before user was available.
    // The solution may be to put layout in gatsby-ssr/browser
    // In the end I think useContext worked as it should've but wasn't given the chance to load user

    return (
        <FirebaseContext.Consumer>
            {props => {
                // if route is for admin and they are a member and have admin priveleges
                if (admin && props.user && props.user.isAdmin) {
                    return <Component {...rest} />
                }
                // if route is for members and they are member
                else if (!admin && props.user) {
                    return <Component {...rest} />
                }
                else {
                    // navigate('/signin'); // Can't use because redirects to signin even when logged in
                    // so we just return nothing and they see header and footer and that's it
                    return null;
                }
            }}
        </FirebaseContext.Consumer>
    );
}

export default PrivateRoute;