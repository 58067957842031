import React from "react";
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Obfuscate from 'react-obfuscate';
import { Router } from "@reach/router";
import { FirebaseContext } from '../components/Firebase';

import SEO from "../components/seo"
import PrivateRoute from '../components/PrivateRoute';
import MembersNav from "../components/Members/MembersNav";
import AdminNav from "../components/Members/Admin/AdminNav";

import InsightDispatch from '../components/Members/InsightDispatch';
import BookSyntheses from '../components/Members/BookSyntheses';
import SelfCoaching from '../components/Members/SelfCoaching';
import Courses from '../components/Members/Courses/Courses';
import CreateUser from "../components/Members/Admin/CreateUser";
import UploadContent from '../components/Members/Admin/UploadContent';

function Dashboard() {
	return (
		<DASHWRAP>
			<h1>Welcome</h1>
			<h3>Welcome to the Insight Lifestyle's Members Area. If this is your first time here, start with the <Link to="/members/courses/welcome-course">Welcome Course</Link>.</h3>
			<ul>
				<li>Then take a look at current and past <Link to="/members/insightdispatch"> Insight Dispatches</Link></li>
				<li>Browse the library of <Link to="/members/booksyntheses">Book Syntheses</Link></li>
				<li>Have questions about a specific point in your growth? Check out some of the questions answered in the <Link to="/members/selfcoaching">Self-Coaching </Link>Database.</li>
				<li>And more coming soon...</li>
			</ul>
			<p>For any questions, comments, feedback, or support, please email <Obfuscate email="support@tonybronzo.com" />.</p>
		</DASHWRAP>
	);
}

const DASHWRAP = styled('div')`
	width: 80%;
	margin: 0 auto;
	padding: 50px 0;

	h1 {
		text-align: center;

	}

	h2 {
		padding-top: 0;
	}

	p, li {
		font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
	}

	a {
		text-decoration: none;
	}
`;

function MembersPage() {
    return (
        <>
            <SEO title="Members" />
            <CENTER>
				<FirebaseContext.Consumer>
					{props => (
							<>
								{props.user && <MembersNav />}
								{props.user && props.user.isAdmin && <AdminNav />}
							</>
					)}
				</FirebaseContext.Consumer>
                <Router>
					<PrivateRoute path="/members" component={Dashboard} />
					<PrivateRoute path="/members/insightdispatch/*" component={InsightDispatch} />
					<PrivateRoute path="/members/booksyntheses/*" component={BookSyntheses} />
					<PrivateRoute path="/members/selfcoaching" component={SelfCoaching} />
					<PrivateRoute path="/members/courses/*" component={Courses} />

					{/* admin only */}
					<PrivateRoute path="/members/createuser" admin={true} component={CreateUser} />
					<PrivateRoute path="/members/uploadcontent" admin={true} component={UploadContent} />
                </Router>
            </CENTER>
        </>
    );
}

const CENTER = styled('main')`
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

// const P = styled('p')`
//     font-size: 20px;
//     width: 80%;
//     text-align: center;
//     padding-bottom: 80px;
//     margin: 0 auto;
//     font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

//     @media (max-width: 500px) {
//       padding-left: 50px;
//       padding-right: 50px;
//       font-size: 15px !important;
//     }
// `;

export default MembersPage;
