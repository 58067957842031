import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Router } from "@reach/router";

import PrivateRoute from '../../PrivateRoute';
import CoursePage from './CoursePage';
// import RawJSON from '../../Testing/RawJSON';

function Courses() {

    const { allCourses: { nodes: courses } } = useStaticQuery(graphql`
        query {
            allCourses (sort: {order: ASC, fields: dateAdded}) {
                nodes {
                    description
                    imageUrl
                    slug
                    title
                    dateAdded
                    id
                    childrenPlaylist {
                        audioUrl
                        duration
                        order
                        title
                        id
                    }
                }
            }
        }
    `);

    return (
        <section>
            <Router>
                <PrivateRoute path='/' component={CourseIndex} courses={courses} />
                {courses.map(course => 
                    <PrivateRoute path={course.slug} component={CoursePage} course={course} key={course.id} />
                )}
            </Router>
            {/* <RawJSON data={courses} title='Course data' /> */}
        </section>
    );
}

function CourseIndex({ courses }) {
    return (
        <INDEXWRAPPER>
            {courses.map(course =>
                <COURSEBOX course={course} key={course.id} />
            )} 
        </INDEXWRAPPER>
    );
}

function COURSEBOX({ course }) {
    return (
        <COURSEBOXSTYLED>
            <img src={`${course.imageUrl}/710x430`} alt='Welcome Course' />
            <div>
                <h2>{course.title}</h2>
                <p>{course.description}</p>
                <BREAK />
                <BUTTTONBOX>
                    <button><Link to={`/members/courses/${course.slug}`}>View Course</Link></button>
                    {/* <p>Bronze Included</p> */}
                </BUTTTONBOX>
            </div>
        </COURSEBOXSTYLED>
    );
}

const INDEXWRAPPER = styled('div')`
    width: 900px;
    padding-bottom: 50px;
    margin: 50px auto;

    @media (max-width: 770px) {
        width: 100%;
    }
`;

const COURSEBOXSTYLED = styled('div')`
    display: flex;
    justify-content: space-between;

    // temp
    ${'' /* border: 1px dotted black; */}

    @media (max-width: 950px) {
        flex-direction: column;
        align-items: center;
    }

    img {
        margin: 0 auto;
        width: 45%;
        height: 320px;
        box-shadow: 0 10px 20px 0 rgba(26,44,57,0.14);
        object-fit: contain;

        @media (max-width: 770px) {
            width: 90%;
        }
    }

    h2 {
        padding-top: 20px;
    }

    div {
        width: 45%;
        display: flex;
        flex-direction: column;

        @media (max-width: 770px) {
            width: 90%;
        }
    }
`;

const BREAK = styled('div')`
    width: 50px;
    height: 2px;
    background-color: #cdd6dc;
    margin: 10px 0;
    align-self: start;
`

const BUTTTONBOX = styled('div')`
    display: flex;

    button {
        width: fit-content;
        margin-top: 40px;
        margin-right: 25px;
    }
`;

export default Courses;