import React from 'react';
import styled from '@emotion/styled';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import RawJSON from '../../Testing/RawJSON';

function CoursePage({ course }) {

    return (
    <WRAPPER>
        {/* header */}
        <div>
            <h1>{course.title}</h1>
            <a href="#contents"><button>Get Started</button></a>
        </div>
        {/* meta */}
        <div>
            <h3>What's included?</h3>
            <p>{`⏯ ${course.childrenPlaylist.length} Audio`}</p>
            {/* 📎 1 File */}
        </div>
        <BREAK />
        <h3 id="contents">Contents</h3>
        <TRACKINDEX>
            {course.childrenPlaylist
                .sort((track1, track2) => track1.order - track2.order)
                .map(track => <ITEMBOX track={track} key={track.id} />
            )}
        </TRACKINDEX>
        {/* <h3>Bonus Content</h3> */}
        {/* bonus content box */}
        {/* <div>
            <ITEMBOX />
            <ITEMBOX />
        </div> */}
        {/* <RawJSON data={course} title='Course' /> */}
    </WRAPPER>
  );
}

function ITEMBOX({ track }) {
    const playerRef = React.useRef()
    const [speed, setSpeed] = React.useState(1)

    function speedHandler() {
        let speedStep = 0.5
        if (speed === 1 || speed === 1.25) {
            speedStep = 0.25
        }
        if (speed === 2.5) {
            playerRef.current.audio.current.playbackRate = 1
            setSpeed(1)
        } else {
            const newSpeed = speed + speedStep
            playerRef.current.audio.current.playbackRate = newSpeed
            setSpeed(newSpeed)
        }
    }

    return (
        <ITEMBOXSTYLED>
            <h3>{track.title}</h3>
            <p>{`⏱ ${track.duration} mins`}</p>
            <AUDIOPLAYER
                preload='none'
                src={track.audioUrl}
                customAdditionalControls={[
                    <button onClick={speedHandler}>{speed + 'x'}</button>
                ]}  // hides loop button
                // onPlay={playHandler}
                defaultCurrentTime="00:00"
                defaultDuration={track.duration}
                ref={playerRef}
            />
        </ITEMBOXSTYLED>
    );
}

const WRAPPER = styled('div')`
  width: 80%;
  margin: 0 auto;
  padding: 50px;

  // temp
  ${'' /* border: 1px solid red; */}

  > div:first-child {
      background-color: hsla(0, 3.16%, 60.76%, 0.73);
      padding: 20px;
      margin-bottom: 50px;
      border-radius: 5px;
  }

  p {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  }

  @media (max-width: 610px) {
      width: 100%;
      padding: 50px 0;
  }
`;

const TRACKINDEX = styled('div')`
    border: 1px solid #E0E7ED;
    border-radius: 5px;
`;

const ITEMBOXSTYLED = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #E0E7ED;

  p {
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  }
`;

const AUDIOPLAYER = styled(AudioPlayer)`
    width: 350px;
    margin: 0 auto;

    @media (max-width: 430px) {
        width: 250px;
    }
`;

const BREAK = styled('div')`
    width: 75px;
    height: 2px;
    background-color: #cdd6dc;
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
`;

export default CoursePage;