import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import { FirebaseContext } from '../../Firebase';

function CreateUser() {
    const { firebase, user } = useContext(FirebaseContext);

    const emptyFormValues = {
        firstName: '',
        lastName: '',
        userName: '',
        level: 'bronze',
        vip: false,
        email: '',
        password: '',
        sendReg: false,
        testing: false,
    }

    const [formValues, setFormValues] = useState(emptyFormValues);
    const [status, setStatus] = useState(null)

    function handleSubmit(event) {
        event.preventDefault();
        setStatus('loading')
        firebase.register({
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            userName: formValues.userName,
            level: formValues.level,
            vip: formValues.vip,
            email: formValues.email,
            password: formValues.password,
            sendReg: formValues.sendReg,
            testing: formValues.testing
        })
        // TODO error handling in case login is unsuccessful
        // this only works if there are no problems
            .then(result => {
                setStatus('done')
            });
    }

    function handleReset() {
        setFormValues(emptyFormValues)
        setStatus(null)
    }

    // React.useEffect(() => {
    //     console.dir(formValues)
    // }, [formValues])

    function handleInputChange(event) {
        event.persist(); // don't need this in React v17
        let value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked
        } else {
            value = event.target.value
        }
        setFormValues(currentValues => ({
                ...currentValues,
                [event.target.name]: value,
            })
        );
    }

    return (
        <div>
            <h1 style={{textAlign: 'center'}}>Create User</h1>
                <FORM onSubmit={handleSubmit} onReset={handleReset}>
                    <input value={formValues.firstName} onChange={handleInputChange} name='firstName' placeholder='First Name' type='text' required minLength={3} />
                    <input value={formValues.lastName} onChange={handleInputChange} name='lastName' placeholder='Last Name' type='text' required minLength={3} />
                    <input value={formValues.userName} onChange={handleInputChange} name='userName' placeholder='username' type='text' minLength={3} />
                    <input value={formValues.email} onChange={handleInputChange} name='email' placeholder='email' type='email' required minLength={3} />
                    <input value={formValues.password} onChange={handleInputChange} name='password' placeholder='password' type="password" required minLength={3} />
                    <select value={formValues.level} name='level' onBlur='' onChange={handleInputChange} required>
                        <option value='bronze'>Bronze</option>
                        <option value='silver'>Silver</option>
                        <option value='gold'>Gold</option>
                        <option value='diamond'>Diamond</option>
                    </select>
                    <div>
                        <label htmlFor='sendReg'>Do you want to send registration email?
                            <input type='checkbox' name='sendReg' checked={formValues.sendReg} onChange={handleInputChange} />
                        </label>
                    </div>
                    <div>
                        <label htmlFor='vip'>VIP?
                            <input type='checkbox' name='vip' checked={formValues.vip} onChange={handleInputChange} />
                        </label>
                    </div>
                    <div>
                        <label htmlFor='testing'>Testing (Don't register account)
                            <input type='checkbox' name='testing' checked={formValues.testing} onChange={handleInputChange} />
                        </label>
                    </div>
                    
                    <button type="submit">Register</button>
                    <button type="reset">Reset</button>
                </FORM>
                {status && <div style={{
                    color: status === 'loading' ? 'red' : 'green',
                    textAlign: 'center',
                }}>{`${status} - USER: ${user.email}`}</div>}
        </div>
    );
}

const FORM = styled('form')`
    display: flex;
    flex-direction: column;
    padding: 0 20%;

    > * {
        margin: 10px 0;
    }

`;

export default CreateUser;