import React from 'react';
import ComingSoon from '../ComingSoon';
// import { graphql, useStaticQuery } from 'gatsby';

function MomentumCalls() {
    // const { allInsightDispatch: { nodes: dispatches }} = useStaticQuery(graphql`
    //     query {
    //         allInsightDispatch {
    //             nodes {
    //                 audioUrl
    //                 duration
    //                 summary
    //                 title
    //                 id
    //             }
    //         }
    //     }
    // `);

    return (
        <section>
            {/* <ul>
                {dispatches.map(dispatch => 
                    <div key={dispatch.id}>
                        <h3>{dispatch.title} - {dispatch.duration}</h3>
                        <audio controls preload="none" src={dispatch.audioUrl} />
                    </div>
                )}
            </ul>
            <small>{JSON.stringify(dispatches, null, 4)}</small> */}
            <ComingSoon isFullWidth={true} text="Coming Soon" />
        </section>
    );
}

export default MomentumCalls;